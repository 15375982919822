import React from 'react'
import { Header } from '../../core/Header/Header'
import Mpin from './Mpin'

function SetNewMpin({title}) {
  return (
    <div>
        <Header headerTitle={"Setup MPIN"} />
        <Mpin title={title}/>
    </div>
  )
}

export default SetNewMpin