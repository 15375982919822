import { SET_HEADER_FOR_WEB_VIEW } from "../ActionTypes";
import { DEFAULT_HEADER_COLOR } from "../../utils/defaultStyling";

const initialState = {
    isHeaderEnabled:false,
    headerColor:DEFAULT_HEADER_COLOR
}

const HeaderForWebViewReducer = (state = initialState, action) =>{
    switch(action.type){
    
        case SET_HEADER_FOR_WEB_VIEW:
            return{
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default HeaderForWebViewReducer