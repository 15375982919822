import { SET_HOME } from "../ActionTypes";

const initialState = {
    headerSection:{
        pwaHomepageTitle:''
    },
    cardConfigSection:{
        virtualCardOrientation:'',
        virtualCardImage:"",
        virtualCardAnimation:'',
        iconOverlay:'',
        iconOverlayImage:"",
        cardDetailsBannerImage:"",
        isQrCodeRequiredForActivation:false,
        isCardControlsInActivationFlow:false
    },
    rewardSection:{
        isRewardEnabled:false,
        rewardAggregateDefinitionId:'',
        rewardGroupingField:'',
        rewardRedirectionType:'',
        rewardLrRedirectionUrl:'',
        rewardEmptyText:'',
        rewardDescText:'',
        rewardBannerImage:""
    },
    offersAndPromotionsSection:{
        isOffersEnabled:false,
        offersListLength:''
    },
    repaymentSection:{
        enableRepaymentFlow:false,
        repaymentUrl:''
    },
    transactionSection:{
        isTransactionEnabled:false,
        displayTxnDateType:"",
        transactionViewType:'',
        transactionEmptyImage:""
    }
}

const HomeReducer = (state = initialState, action) => {

    switch(action.type){
        case SET_HOME:
            return{
                ...state,
                ...action.payload
            }
        default:
            return state
    }

}

export default HomeReducer