import React from 'react'
import { Header } from '../core/Header/Header'
import { DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR } from '../../utils/defaultStyling'
import { useSelector } from 'react-redux'
import { billEMI } from '../../assets/images'
import hexToRGBA from '../../core/HextoRgba'

function ActiveEMI() {
    const [colorSchema] = useSelector(state => [state.ColorSchema])
  return (
    <div className='row'>
        <Header headerTitle="My Active EMIs" />
        <div className='d-flex align-items-center' style={{fontWeight:'600',color:colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR}}>
            <div className='p-2' style={{width:'50%',textAlign:'center',borderBottom: `2px solid ${colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR}`,backgroundColor:hexToRGBA('#1F0600', 0.3)}} >Bill EMIs</div>
            <div className='p-2' style={{width:'50%',textAlign:'center'}} >Transaction EMIs</div>
        </div>
        <div className='w-100 flex-column-center'>
            <div className='flex-column-center' style={{width:'80%',marginTop:'30%'}}>
                <div className='imgConta'>
                    <div className='d-flex align-items-center justify-content-center' style={{height:'90px',width:'90px',backgroundColor:'#F7F7F7'}}>
                        <img src={billEMI} />
                    </div>
                </div>
                <h5 className='mt-2'>No Balance EMIs</h5>
                <div style={{fontSize:'14px',textAlign:'center'}}>
                    You can convert your upcoming bill amount to EMI once the statemnet is generated
                </div>
            </div>
        </div>
    </div>
  )
}

export default ActiveEMI