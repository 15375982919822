import { combineReducers } from "redux"
import theme from "./Theme"
import ColorSchema from "./ColorSchema"
import HeaderForWebView from "./HeaderForWebView"
import PreLogin from "./PreLogin"
import Home from "./Home"
import Settings from "./Settings"

export default combineReducers({
  theme,
  ColorSchema,
  HeaderForWebView,
  PreLogin,
  Home,
  Settings
})
