export const DEFAULT_HEADER_BG_COLOR = "#000000"
export const DEFAULT_TITLE_COLOR = "#FFFFFF"
export const DEFAULT_WIDGET_BG_COLOR = "#D5FFF5"
export const DEFAULT_PRIMARY_COLOR = "#000000"
export const DEFAULT_SECONDARY_COLOR = "#D5FFF5"
export const DEFAULT_TOAST_BG_COLOR = "#D5FFF5"
export const DEFAULT_TOAST_COLOR = "#000000"
export const DEFAULT_BG_COLOR = "#5DF398"
export const DEFAULT_APP_TEXT_COLOR = "#000000"
export const DEFAULT_DESKTOP_BG_COLOR = "#E7FFFC"
export const DEFAULT_HEADER_COLOR = "#000000"