import React from 'react'

function SmartNudges({url,title,subtitle}) {
  return (
    <div className='home-widget-mainContainer'>
        <div className='d-flex align-items-center'>
            <div className='imag'>
                <img src={url} />
            </div>
            <div style={{marginLeft:'10px'}}>
                <div style={{fontWeight:'600',color:'black'}}>{title}</div>
                <div style={{color:'gray',fontSize:'12px'}}>{subtitle}</div>
            </div>
        </div>
    </div>
  )
}

export default SmartNudges