import React from 'react'
import { Header } from '../core/Header/Header'
// import cardControls from '../../assets/images/cardControlIconV2.svg'
import { aboutCardProg, blockReplaceIcon, cardControlsIconV2, changeMpin, customerCare, manageEmi } from '../../assets/images'
import Widget from './components/Widget'
import { useSelector } from 'react-redux'

function Home() {
  const [settings] = useSelector(state => [state.Settings])
  return (
    <div>
        <Header headerTitle="My Card - Settings" />
        <div className='w-100 flex-column-center p-3 mt-2'>
          <Widget url={cardControlsIconV2} text = "Card Controls & Limits" />
          {settings.myActiveEmis?.enableManageEmi && <Widget url={manageEmi} text = "My Active EMIs" />}
          {settings.blockOrReplaceCard?.isBlockReplaceCardEnabled && <Widget url={blockReplaceIcon} text = "Block/Replace Card" />}
          {settings.changeMpin?.isResetMpinEnabled && <Widget url={changeMpin} text = "Change MPIN" />}
          {settings.customerCare?.isCustomerCareEnabled && <Widget url={customerCare} text = "Customer Care" />}
          {settings.aboutCardprogram?.isAboutProgramEnabled && <Widget url={aboutCardProg} text = "About Card Program" />}
          {settings && <Widget url={manageEmi} text = "Analyse Spends" />}
        </div>
    </div>
  )
}

export default Home