import React from "react"
import banner from "../../assets/images/preLoaderTransparent.gif"
import bankDefaultIcon from "../../assets/images/bankDefaultIcon.svg"
import preLoaderBanner from "../../assets/images/preloaderBanner.svg"

import HyperfaceLogo from "../../assets/images/HyperfaceLogo.svg"
import '../../utils/styles.css'
import { DEFAULT_PRIMARY_COLOR } from "../../utils/defaultStyling"
import { useSelector } from "react-redux"
const Preloader = () => {

  const [preLogin,colorSchema] = useSelector(state => [state.PreLogin,state.ColorSchema])
  console.log("Preloader",preLogin)
  return (
    <div
      className="flex-column-center" style={{color:DEFAULT_PRIMARY_COLOR,fontSize:'14px'}}
    >
      <div className="" style={{marginTop:'25%'}}>
        <img src={preLoaderBanner}></img>
      </div>
      <div className="flex-column-center mt-3">
        <div className="flex-column-center">
        <div className="">Establishing a secure link with</div>
        <div className="">{preLogin?.preLoaderScreen?.preLoaderBankName || "Hyperface"}</div>
        </div>
        <div className="mt-2">
          <img src={bankDefaultIcon}/>
        </div>
      </div>
      <div className="flex-column-center mt-3">
        <div className="mb-2">Powered by</div>
        <img src={HyperfaceLogo} />
      </div>
    </div>
  )
}

export default Preloader
