import React from 'react'
import "./Header.css"
import { useLocation } from 'react-router'
import Paths from '../../../routes/Paths'
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSelector } from 'react-redux';
import { DEFAULT_HEADER_BG_COLOR, DEFAULT_TITLE_COLOR } from '../../../utils/defaultStyling';
export const Header = ({headerTitle}) => {
  const [colorSchema, HeaderForWebView,home] = useSelector(state =>[state.ColorSchema, state.HeaderForWebView,state.Home])
  const location = useLocation()
  function renderHeader(){
  
  const url = location.pathname 
  const style = {backgroundColor: colorSchema.header.headerBackgroundColor?colorSchema.header.headerBackgroundColor:DEFAULT_HEADER_BG_COLOR,color:colorSchema.header.headerTextColor
    ?colorSchema.header.headerTextColor
    :DEFAULT_TITLE_COLOR,
    height:'50px'
  }
  return (
    <div>
        {HeaderForWebView?.isHeaderEnabled && <div style={{backgroundColor: HeaderForWebView?.headerColor,height:'30px'}}></div>}
        <div style={style} className={url === Paths.HOME ? 'home-header-mainContainer' : 'sessionExpired-header-mainContainer'}>
          <ArrowBackIosIcon className='backArrow'/>
          <div style={{width:'85%'}} className="home-header-mainContainer-title">
            {url === Paths.HOME ? (home.headerSection.pwaHomepageTitle || headerTitle) : headerTitle}
          </div>
           {url === Paths.HOME && <SettingsIcon />}
        </div>
    </div>
  )
}

  return (
    <div className=''>
      {renderHeader()}
    </div>
  )
}
