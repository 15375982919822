import React from 'react'
import { Header } from '../../core/Header/Header'
import { useSelector } from 'react-redux'
import { call, mail } from '../../../assets/images'
import { DEFAULT_SECONDARY_COLOR } from '../../../utils/defaultStyling'


const CustomerCare = () => {
    const [settings,colorSchema] = useSelector(state => [state.Settings,state.ColorSchema])
  return (
    <>
        <Header headerTitle = "Customer Care" />
        <div className='p-3'>
        <div className='home-widget-mainContainer mt-3 flex-column align-items-start' style={{color: colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR}}>
            <div className='mb-3' style={{
                fontWeight: '500',
                fontSize: '16px'
            }}>
                {settings.customerCare.customerCareDescText || 'Description Text'}
            </div>
            <div style= {{
                fontWeight: '600',
                fontSize: '12px'
            }}>
                You can call us on
            </div>
            <div className='d-flex align-items-center mb-4'>
                <img src={call}
                    style = {{
                        height :'20px',
                        marginRight: '10px'
                    }}
                />
                <div>
                   {settings.customerCare.customerCareMoblieNo || '1800 123'}
                </div>
            </div>

            <div style= {{
                fontWeight: '600',
                fontSize: '12px'
            }}>
                or Email us at
            </div>
            <div className='d-flex align-items-center'>
                <img src={mail}
                    style = {{
                        height :'20px',
                        marginRight: '10px'
                    }}
                />
                <div>
                    {settings.customerCare.customerCareEmail || 'support@hyperface.co'}
                </div>
            </div>
        </div>
        </div>
    </>
  )
}

export default CustomerCare
