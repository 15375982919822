import React from 'react'
import { Toggle } from '../../../core/Toggle'
import { Slider } from '../../../core/Slider'

function CardControlWidget({url,checked,title}) {
  return (
    <div className='innerWidget mt-2' style={{
        paddingBottom: "10px",
        borderBottom: title !== 'Cash Withdrawl' ? '1px solid gray' : '',
        background: !checked && 'lightgray' 
      }}>
    <div className='cardControl-innerWidget-heading d-flex justify-content-between p-2'>
      <div className='d-flex align-items-center'>
        <img src={url} />
        <div 
        style={{
          fontSize: '14px',
          fontWeight: '500',
          paddingLeft:'10px'
        }}
      >
        {title}
      </div>
      </div>
      <div>
        <Toggle checked={checked}/>
      </div>  
    </div>
    <div className="cardControl-innerWidget-content"
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <div className = "cardControl-innerWidget-value w-25"
        style={{
          borderBottom: 'solid',
          fontSize: '18px',
          fontWeight: 700
        }}
      >
        {checked ? '₹ 10,000' : 'N/A'}
      </div>
      <div className="cardControl-innerWidget-Slider d-flex p-2">
        <div
          style={{
            fontSize: '14px',
            fontWeight: '400'
          }}
        >
          Min ₹0
        </div>
        <div style={{paddingLeft:'5px',paddingRight:'5px'}}>
          <Slider checked={checked}/>
        </div>
        <div
          style={{
            fontSize: '14px',
            fontWeight: '400'
          }}
        >
          Max ₹10,000
        </div>
      </div>
    </div>
  
  
  
  </div>
  )
}

export default CardControlWidget