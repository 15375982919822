import React,{useRef} from 'react'
import { Animated } from "react-native"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './CardAndBalance.css'
import { useSelector } from 'react-redux';
import { DEFAULT_PRIMARY_COLOR, DEFAULT_WIDGET_BG_COLOR,DEFAULT_SECONDARY_COLOR } from '../../../../utils/defaultStyling';
import { emptyRewardDefault, horizontalCard, verticalCard } from '../../../../assets/images';
import { CardOrientation } from '../../../../utils/constants';
import './styles.css'

const animationName = ["CARD_FLASH", "TILT_SHAKING", "GROW", "VIEW_CARD"]
const overlayPosition = {
  BOTTOM: "BOTTOM",
  RIGHT: "RIGHT",
  NONE: "NONE",
}
const CardAndBalance = () => {
  const [theme, colorSchema,home] = useSelector(state=>[state.theme, state.ColorSchema, state.Home])
  const animatedController = useRef(new Animated.Value(0)).current
  
  animatedController.interpolate({
    inputRange: [-1, 1],
    outputRange: ["-0.8rad", "0.8rad"],
  })
  const viewCard = true
  const overlayImageUrl = home.cardConfigSection.iconOverlayImage || ""
  const overlay = home.cardConfigSection.iconOverlay || overlayPosition.NONE
  const animation = home.cardConfigSection.virtualCardAnimation || "NONE"

  const renderHorizontal = () => {
    switch (animation) {
      case animationName[0]:
        return (
          <div
            className={`container-vertical ${
              viewCard ? "container-vertical-viewcard" : ""
            }`}
          >
            <div className='strip'></div>
            <div className='rectangle-vertical'>
              <div
                style={{borderRadius:'12px',boxShadow:'2px solid black',position:'relative'}}
              >
                <img
                  src={home.cardConfigSection?.virtualCardImage || horizontalCard}
                  // className="zoom-horizontal"
                  height='165px'
                  width='261px'
                  alt='Alternate Text '
                  style={{borderRadius:"12px"}}
                />
                {overlay === overlayPosition.RIGHT  && overlayImageUrl? (
                  <div>
                    <img
                      src={overlayImageUrl}
                      width='14px'
                      height='70px'
                      alt='Alternate Text '
                      style={{borderRadius:'12px',position:'absolute',right:'0px',top:'42px'}}
                    />
                  </div>
                ) : null}
                {overlay === overlayPosition.BOTTOM  && overlayImageUrl? (
                  <div style={{ position: "relative" }}>
                    <img
                      src={overlayImageUrl}
                      width='70px'
                      height='14px'
                      alt='Alternate Text '
                      style={{borderRadius:'12px',position:'absolute',right:'88px',bottom:'0px'}}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )
      case animationName[1]:
        return (
          <div className='tilt-shaking'>
            <div
              style={{borderRadius:'12px',boxShadow:'2px solid black',position:'relative'}}
            >
              <img
                src={home.cardConfigSection?.virtualCardImage || horizontalCard}
                // className="zoom-horizontal"
                height='165px'
                width='261px'
                alt='Alternate Text'
                style={{borderRadius:"12px"}}
              />
              {overlay === overlayPosition.RIGHT  && overlayImageUrl? (
                <div>
                  <img
                    src={overlayImageUrl}
                    width='14px'
                    height='70px'
                    alt='Alternate Text '
                    style={{borderRadius:'12px',position:'absolute',right:'0px',top:'42px'}}
                  />
                </div>
              ) : null}
              {overlay === overlayPosition.BOTTOM  && overlayImageUrl? (
                <div>
                  <img
                    src={overlayImageUrl}
                    width='70px'
                    height='14px'
                    alt='Alternate Text '
                    style={{borderRadius:'12px',position:'absolute',right:'88px',bottom:'0px'}}
                  />
                </div>
              ) : null}
            </div>
          </div>
        )
      case animationName[2]:
        return (
          <div className='d-flex align-items-center justify-content-center'>
            <div
              className='grow-horizontal'
              style={{ backgroundColor: colorSchema.cta.primaryColor || DEFAULT_PRIMARY_COLOR }}
            ></div>
            {/* <div className='grow-outline'></div> */}
            <div
              style={{zIndex:'1',borderRadius:'12px',alignSelf:'center',justifySelf:'center',boxShadow:'2px solid black',position:'relative'}}
            >
              <img
                src={home.cardConfigSection?.virtualCardImage || horizontalCard}
                // className="zoom-vertical"
                // className="flip"
                height='165px'
                width='261px'
                alt='Alternate Text '
                style={{borderRadius:"12px"}}
              />
              {overlay === overlayPosition.RIGHT  && overlayImageUrl? (
                <div>
                  <img
                    src={overlayImageUrl}
                    width='14px'
                    height='70px'
                    alt='Alternate Text '
                    style={{borderRadius:'12px',position:'absolute',right:'0px',top:'42px'}}
                  />
                </div>
              ) : null}
              {overlay === overlayPosition.BOTTOM  && overlayImageUrl? (
                <div>
                  <img
                    src={overlayImageUrl}
                    width='70px'
                    height='14px'
                    alt='Alternate Text '
                    style={{borderRadius:'12px',position:'absolute',right:'88px',bottom:'0px'}}
                  />
                </div>
              ) : null}
            </div>
          </div>
        )
      case animationName[3]:
        return (
          <div className='align-items-center justify-content-center'>
            <div style={{ backgroundColor: colorSchema.cta.primaryColor || DEFAULT_PRIMARY_COLOR, zIndex:'1' }}></div>
            <div
              className='mb-0 pt-1 align-items-center justify-content-center'
          
              style={{zIndex:'1',borderRadius:'12px',cursor:'pointer',width:'261px',height:'165px',backgroundColor:colorSchema.widget.widgetBackgroundColor || DEFAULT_WIDGET_BG_COLOR,boxShadow:'2px solid black',position:'relative'}}
            >
              <img
                src={home.cardConfigSection?.virtualCardImage || horizontalCard}
                height='130px'
                width='222px'
                alt='Alternate Text '
                style={{borderRadius:"12px"}}
              />
              <div>
                <div
                  style={{fontWeight:'bold',marginTop:'0px',paddingBottom:'4px',textAlign:'center',color:colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR}}
                  
                >
                  View Card
                </div>
              </div>
            </div>
          </div>
        )
      default:
        return (
          <div
            style={{borderRadius:'12px',boxShadow:'4px solid black',position:'relative'}}
          >
            <img
              src={home.cardConfigSection?.virtualCardImage || horizontalCard}
              className='zoom-horizontal'
              height='165px'
              width='261px'
              alt='Alternate Text '
              style={{borderRadius:"12px"}}
            />
            {overlay === overlayPosition.RIGHT  && overlayImageUrl? (
              <div>
                <img
                  src={overlayImageUrl}
                  width='14px'
                  height='70px'
                  alt='Alternate Text '
                  style={{borderRadius:'12px',position:'absolute',right:'0px',top:'42px'}}
                />
              </div>
            ) : null}
            {overlay === overlayPosition.BOTTOM  && overlayImageUrl? (
              <div>
                <img
                  src={overlayImageUrl}
                  width='70px'
                  height='14px'
                  alt='Alternate Text '
                  style={{borderRadius:'12px',position:'absolute',right:'88px',bottom:'152'}}
                />
              </div>
            ) : null}
          </div>
        )
    }
  }
  const renderVertical = () => {
    switch (animation) {
      case animationName[0]:
        return (
          <div className='container-horizontal'>
            <div className='strip'></div>
            <div className='rectangle'>
              <div
                style={{borderRadius:'12px',zIndex:'1',boxShadow:'4px solid black',position:'relative'}}
              >
                <img
                  src={home.cardConfigSection?.virtualCardImage || verticalCard}
                  // className="zoom-vertical"
                  // className="flip"
                  height='261px'
                  width='165px'
                  alt='Alternate Text '
                  style={{borderRadius:"12px"}}
                />
                {overlay === overlayPosition.RIGHT  && overlayImageUrl? (
                  <div>
                    <img
                      src={overlayImageUrl}
                      width='14px'
                      height='70px'
                      alt='Alternate Text '
                      style={{borderRadius:'12px',position:'absolute',right:'0px',top:'95px'}}
                    />
                  </div>
                ) : null}
                {overlay === overlayPosition.BOTTOM  && overlayImageUrl? (
                  <div>
                    <img
                      src={overlayImageUrl}
                      width='70px'
                      height='14px'
                      alt='Alternate Text '
                      style={{borderRadius:'12px',position:'absolute',right:'50px',bottom:'0px'}}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )
      case animationName[1]:
        return (
          <div className='tilt-shaking'>
            <div 
            style={{borderRadius:'12px',zIndex:'1',boxShadow:'4px solid black',position:'relative'}}
            >
              <img
                src={home.cardConfigSection?.virtualCardImage || verticalCard}
                // className="zoom-vertical"
                // className="flip"
                height='261px'
                width='165px'
                alt='Alternate Text '
                style={{borderRadius:"12px"}}
              />
              {overlay === overlayPosition.RIGHT  && overlayImageUrl? (
                <div>
                  <img
                    src={overlayImageUrl}
                    width='14px'
                    height='70px'
                    alt='Alternate Text '
                    style={{borderRadius:'12px',position:'absolute',right:'0px',top:'95px'}}
                  />
                </div>
              ) : null}
              {overlay === overlayPosition.BOTTOM  && overlayImageUrl? (
                <div>
                  <img
                    src={overlayImageUrl}
                    width='70px'
                    height='14px'
                    alt='Alternate Text '
                    style={{borderRadius:'12px',position:'absolute',right:'50px',bottom:'0px'}}
                  />
                </div>
              ) : null}
            </div>
          </div>
        )
      case animationName[2]:
        return (
          <div className='align-items-center justify-content-center'>
            <div className='grow' style={{ backgroundColor: colorSchema.cta.primaryColor || DEFAULT_PRIMARY_COLOR,zIndex:'1' }}></div>
            {/* <div className='grow-outline'></div> */}
            <div
              style={{borderRadius:'12px',zIndex:'1',boxShadow:'4px solid black',alignSelf:'center',justifySelf:'center',position:'relative'}}
            >
              <img
                src={home.cardConfigSection?.virtualCardImage || verticalCard}
                // className="zoom-vertical"
                // className="flip"
                height='261px'
                width='165px'
                alt='Alternate Text '
                style={{borderRadius:"12px"}}
              />
              {overlay === overlayPosition.RIGHT  && overlayImageUrl? (
                <div>
                  <img
                    src={overlayImageUrl}
                    width='14px'
                    height='70px'
                    alt='Alternate Text '
                    style={{borderRadius:'12px',position:'absolute',right:'0px',top:'95px'}}
                  />
                </div>
              ) : null}
              {overlay === overlayPosition.BOTTOM  && overlayImageUrl? (
                <div>
                  <img
                    src={overlayImageUrl}
                    width='70px'
                    height='14px'
                    alt='Alternate Text '
                    style={{borderRadius:'12px',position:'absolute',right:'50px',bottom:'0px'}}
                  />
                </div>
              ) : null}
            </div>
          </div>
        )

      case animationName[3]:
        return (
          <div className='align-items-center justify-content-center'>
            <div style={{ backgroundColor: colorSchema.cta.primaryColor || DEFAULT_PRIMARY_COLOR }}></div>
            <div
              style={{borderRadius:'12px',zIndex:'1',borderRadius:'12px',alignSelf:'center',justifySelf:'center',boxShadow:'4px solid black',cursor:'pointer',height:'261px',width:'160px',backgroundColor: colorSchema.widget.widgetBackgroundColor || DEFAULT_WIDGET_BG_COLOR,position:'relative'}}
            >
              <img
                style={{margin:'auto',borderRadius:'12px'}}
                src={home.cardConfigSection?.virtualCardImage || verticalCard}
                height='212px'
                width='135px'
                alt='Alternate Text '
              />
              <div>
                <div
                  style={{fontWeight:'bold',textAlign:'center',color:colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR}}
                  className='mt-2 pb-2'
                >
                  View Card
                </div>
              </div>
            </div>
          </div>
        )

      default:
        return (
          <div
            style={{borderRadius:'12px',boxShadow:'4px solid black',position:'relative'}}
          >
            <img
              src={home.cardConfigSection?.virtualCardImage || verticalCard}
              // className="zoom-vertical"
              width='165px'
              height='261px'
              alt='Alternate Text '
              style={{borderRadius:"12px"}}
            />
            {overlay === overlayPosition.RIGHT  && overlayImageUrl? (
              <div>
                <img
                  src={overlayImageUrl}
                  width='14px'
                  height='70px'
                  alt='Alternate Text '
                  style={{borderRadius:'12px',position:'absolute',right:'0px',top:'95px'}}
                />
              </div>
            ) : null}
            {overlay === overlayPosition.BOTTOM  && overlayImageUrl? (
              <div>
                <img
                  src={overlayImageUrl}
                  width='70px'
                  height='14px'
                  alt='Alternate Text '
                  style={{borderRadius:'12px',position:'absolute',right:'50px',bottom:'0px'}}
                />
              </div>
            ) : null}
          </div>
        )
    }
  }
  return (
      <div>
        {
        home.cardConfigSection.virtualCardOrientation === CardOrientation.HORIZONTAL ? 
       ( <div className='flex-column-center mt-3'>
            {/* <div className='d-flex align-items-center'>
              <img src={home.cardConfigSection?.virtualCardImage || horizontalCard} width="260px" height="160px"/>
            </div> */}
            {renderHorizontal()}
            {home.rewardSection?.isRewardEnabled && <div className='home-widget-mainContainer d-flex align-items-center justify-content-between mt-2' style={{width:'258px',borderRadius:'5px',padding:'5px'}}>
              <div className=' d-flex align-items-center justify-content-between p-2'>
                <a href="#" style={{pointerEvents:'none',textDecoration:'none',color : colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR}}>View Rewards</a>
                <ArrowForwardIosIcon style={{fontSize:'13px',color : colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR}}/>
              </div>
              <img src={emptyRewardDefault} />
            </div>}
        </div>)
         
        : 
        (<div className='home-cardAndBalance-mainContainer mt-3' style={{justifyContent: home.rewardSection?.isRewardEnabled ? 'space-between' : 'center'}}>
          {/* <div  className="home-cardAndBalanceWidget-cardContainer">
            <img  width ={"100%"} src={home.cardConfigSection?.virtualCardImage || verticalCard} alt="" />
          </div> */}
          {renderVertical()}
          {home.rewardSection?.isRewardEnabled && <div style={{backgroundColor: colorSchema.widget.widgetBackgroundColor?colorSchema.widget.widgetBackgroundColor:DEFAULT_WIDGET_BG_COLOR}} className="home-cardAndBalanceWidget-rewardsSection">
            <div className="mt-3" style={{width: '100%',display: 'flex',alignItems: 'center',justifyContent: 'center'}} >
              {/* to remove */}
              <img src={home.rewardSection?.rewardBannerImage || emptyRewardDefault} height="90px" /> 
            </div>
            <div  className="flex-column-center mt-2 p-2" >
              <div className='p-2' style={{backgroundColor:'#f6f6f6',borderRadius:'5px'}}>
                <div style={{fontSize:'12px'}}>Rewards Accured in Current Cycle</div>
                <div className="d-flex align-items-center justify-content-between" style={{fontWeight:'600',fontSize:'16px'}}>  
                <div>0</div>
                <ArrowForwardIosIcon className='h-c-r-navigation-icon'/>
                </div>
                
              </div>
              <button className='btn btn-primary mt-2 w-100' style={{backgroundColor: colorSchema.cta.primaryColor || DEFAULT_PRIMARY_COLOR, color: colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR, border: "solid", borderWidth: "2px", borderColor:colorSchema.cta.primaryColor || DEFAULT_PRIMARY_COLOR,fontSize:'14px',fontWeight:'bold'}}>Manage Benefits</button>
            </div>
          </div>}
          </div>
        )
      }
      </div>
  )
}

export default CardAndBalance
