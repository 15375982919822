import CustomRoutes from "./routes/Routes"
import { useEffect } from "react"
import "./App.css"
import { useDispatch, useSelector } from "react-redux"
import { setDesign } from "../src/store/actions/Theme"
import { DEFAULT_APP_TEXT_COLOR, DEFAULT_BG_COLOR, DEFAULT_DESKTOP_BG_COLOR } from "./utils/defaultStyling"
import { setColorSchema } from "./store/actions/ColorSchema"
import { setHeaderForWebView } from "./store/actions/HeaderForWebView"
import { setPreLogin } from "./store/actions/PreLogin"
import {setHome} from './store/actions/Home'
import {setSettings} from './store/actions/Settings'



function App() {
  const dispatch = useDispatch()
  useEffect( ()=>{
    const handleMessage = async (event)=>{
        if(event.origin === process.env.REACT_APP_DASHBOARD_URL){
            const data = await JSON.parse(event.data)
            dispatch(
              setColorSchema(data.colorSchema)
            )
            dispatch(
              setHeaderForWebView(data.headerForWebView)
            )
            dispatch(setPreLogin(data.preLogin))
            dispatch(setHome(data.home))
            dispatch(setSettings(data.settings))
        }
    }
    window.addEventListener('message',handleMessage)
     
    return () =>{
        window.removeEventListener('message',handleMessage)
    }
},[])
  const [ColorSchema] = useSelector(state=>[state.ColorSchema])
  return (
    <div style = {{backgroundColor: ColorSchema.background.deskTopBackgroundColor || DEFAULT_DESKTOP_BG_COLOR}}>
      <div style={{backgroundColor: ColorSchema.background.backgroundColor?ColorSchema.background.backgroundColor:DEFAULT_BG_COLOR, color: ColorSchema.background.textColor?ColorSchema.background.textColor:DEFAULT_APP_TEXT_COLOR,border:'1px solid lightgray'}} className="main-container">
            <CustomRoutes/>
      </div>
    </div>
  )
}

export default App