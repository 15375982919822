import React from 'react'
import './Transaction.css'
import { useSelector } from 'react-redux'
import { DEFAULT_WIDGET_BG_COLOR } from '../../../../utils/defaultStyling'
import emptyTransaction from '../../../../assets/images/emptyTransactions.svg'

const Transaction = () => {
  const [ColorSchema,home] = useSelector(state=>[state.ColorSchema,state.Home])
  return (
    <div style={{backgroundColor: ColorSchema.widget?.widgetBackgroundColor || DEFAULT_WIDGET_BG_COLOR}} className='home-transactionWidget-mainContainer'>
      <div className="trasactionWidget-heading">
        Recent Transactions
      </div>
      <div className="transactionWidget-emptyTransactions-container">
        <img src={home.transactionSection?.transactionEmptyImage || emptyTransaction} alt="" />
        <div className='transactionWidget-emptyTransactionMessage'>No Transactions Yet</div>
      </div>
    </div>
  )
}

export default Transaction
