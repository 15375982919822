const Paths = Object.freeze({
    HOME: "/",
    SESSION_EXPIRED: "/sessionExpired",
    PRE_LOGIN: "/loader",
    SETUP_MPIN: "/setupMPIN",
    SET_MPIN: "/setMPIN",
    FORGET_MPIN : "/forgetMPIN",
    ENTER_MPIN : "/enterMpin",
    ACCOUNT_CLOSED : "/accountClosed",
    SETTINGS:"/manageCard",
    ACTIVE_EMI : "/manageCard/activeEMI",
    CHANGE_MPIN: "/manageCard/changeMpin",
    CARD_SETTINGS : "/manageCard/cardSettings",
    ABOUT_CARD:  "/manageCard/aboutProgram",
    CUSTOMER_CARE: "/manageCard/customerCare",
    BLOCK_CARD: "/manageCard/blockCard",
    ANALYSE_SPEND: "/manageCard/analyseSpend"
  })
  
  export default Paths
  