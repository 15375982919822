export const FLOAT_REGEX = /^\d*\.?\d*$/
export const INTEGER_REGEX = /^\d*?\d*$/
export const EXTERNAL_PATH_ROOT = "external"
export const PHYSICAL_CARD_ACTIVATION_PATH = "PhysicalCardActivation"
export const TRANSACTION_LOGO_WIDTH = 50
export const TRANSACTION_LOGO_HEIGHT = 50
export const DEFAULT_OTP_SMS_SIZE = 4
export const DEFAULT_OTP_EMAIL_SIZE = 4
export const DEFAULT_MPIN_SIZE = 6
export const HEADER_HEIGHT_IN_PX = 70

export const Envs = Object.freeze({
  DEV: "dev",
  UAT: "uat",
  SANDBOX: "sandbox",
  PROD: "prod",
  AU_SANDBOX: "au-sandbox",
})

export const ProgramTypes = Object.freeze({
  CREDIT_CARD: "CREDIT_CARD",
  PAY_LATER: "PAY_LATER",
  PREPAID: "PREPAID",
  OTHER: "OTHER",
})

export const CardOrientation = Object.freeze({
  HORIZONTAL: "HORIZONTAL",
  VERTICAL: "VERTICAL",
})

export const AuthChallenges = Object.freeze({
  OTP_SMS: "OTP_SMS",
  OTP_EMAIL: "OTP_EMAIL",
  MPIN: "MPIN",
  VERIFY_CUSTOMER: "VERIFY_CUSTOMER",
})

export const AuthScopes = Object.freeze({
  FIRST_FACTOR: "FIRST_FACTOR",
  SECOND_FACTOR: "SECOND_FACTOR",
  STEP_UP: "STEP_UP",
})

export const EmiStatus = Object.freeze({
  NOT_CREATED: "NOT_CREATED",
  PENDING: "PENDING",
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
  FORECLOSED: "FORECLOSED",
  CLOSED: "CLOSED",
})

export const OtpErrors = Object.freeze({
  RETRIES_EXCEEDED: "RETRIES_EXCEEDED",
  BLOCKED_TEMPORARILY: "BLOCKED_TEMPORARILY",
  INVALID_OTP: "INVALID_OTP",
  OTP_EXPIRED: "OTP_EXPIRED",
})

export const MpinErrors = Object.freeze({
  INCORRECT_MPIN: "INCORRECT_MPIN",
  BLOCKED: "BLOCKED",
})

export const TransactionDateTypes = Object.freeze({
  TRANSACTION_DATE: "TRANSACTION_DATE",
  POSTING_DATE: "POSTING_DATE",
})

export const DisplayConfig = Object.freeze({
  MAX_WIDTH: "450px",
})
