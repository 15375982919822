import { useSelector } from "react-redux"
import { Label } from "./Label"
import './Styles.css'
import { DEFAULT_SECONDARY_COLOR } from "../utils/defaultStyling"

const Toggle = ({checked}) => {
  const [colorSchema] = useSelector(state => [state.ColorSchema])
  return (
    <div className='hf-toggle-container'>
      <div className='hf-toggle-parent'
        style={{
          height: "15px"
        }}
      >
        <input
          type='checkbox'
          className="hf-toggle"
          checked={checked}
          
        />
        <div className='hf-toggle-switch' style = {{backgroundColor: checked ? colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR : 'gray'}}></div>
      </div>
    </div>
  )
}

export { Toggle }
