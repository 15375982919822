import { Route, Routes } from "react-router"
import Home from "../components/home/home"
import SessionExpired from "../components/sessionExpired/sessionExpired"
import Paths from "./Paths"
import Preloader from "../components/PreLoader/preloader"
import SetMpinIntro from "../components/auth/mpin/SetMpinIntro"
import SetNewMpin from "../components/auth/mpin/SetNewMpin"
import EnterMpin from "../components/auth/mpin/EnterMpin"
import AccountClosed from "../components/AccountClosed"
import SettingsHome from '../components/settings/Home'
import ActiveEMI from "../components/settings/ActiveEMI"
import CardControls from "../components/settings/cardControls/CardControls"
import AboutCardProgram from "../components/settings/aboutCardProgram/AboutCardProgram"
import CustomerCare from "../components/settings/customerCare/CustomerCare"
import BlockCard from "../components/settings/blockCard/BlockCard"
import AnalyseSpend from "../components/settings/AnalyseSpend/spendAnalysis"

const CustomRoutes = () => {
  return (
    <Routes>
      <Route path={Paths.HOME} element={<Home />} />
      <Route path={Paths.SESSION_EXPIRED} element={<SessionExpired />} />
      <Route path={Paths.PRE_LOGIN} element={<Preloader />} />
      <Route path={Paths.SETUP_MPIN} element={<SetMpinIntro />} />
      <Route path={Paths.SET_MPIN} element={<SetNewMpin title="setMPIN" />} />
      <Route path={Paths.FORGET_MPIN} element={<SetNewMpin title="forgetMPIN"/>} />
      <Route path={Paths.ENTER_MPIN} element={<EnterMpin title="enterMPIN"/>} />
      <Route path={Paths.CHANGE_MPIN} element={<SetNewMpin title="changeMPIN"/>} />
      <Route path={Paths.ACCOUNT_CLOSED} element={<AccountClosed />} />
      <Route path={Paths.SETTINGS} element={<SettingsHome />} />
      <Route path={Paths.ACTIVE_EMI} element={<ActiveEMI />} />
      <Route path={Paths.CARD_SETTINGS} element={<CardControls />}/>
      <Route path={Paths.ABOUT_CARD} element={<AboutCardProgram />}/>
      <Route path={Paths.CUSTOMER_CARE} element={<CustomerCare/>}/>
      <Route path={Paths.BLOCK_CARD} element={<BlockCard/>}/>
      <Route path={Paths.ANALYSE_SPEND} element={<AnalyseSpend/>}/>
    </Routes>
  )
}

export default CustomRoutes
