import { useSelector } from "react-redux"
import { DEFAULT_SECONDARY_COLOR } from "../utils/defaultStyling"

const Slider = ({
  min = 0,
  max = 100,
  value = 100,
  checked
}) => {
  const trackLengthCoveredInPercentage = (value / Math.abs(max - min)) * 100
  const [colorSchema] = useSelector(state => [state.ColorSchema])

  return (
    <input
      type='range'
      className={`hf-slider`}
      style={{
        backgroundSize: `${trackLengthCoveredInPercentage}% 100%`,
        background: checked ? colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR : 'gray'
      }}
      min={min}
      max={max}
      value={checked ? value : 0}
    />
  )
}

export { Slider }
