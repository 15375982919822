import { DEFAULT_APP_TEXT_COLOR, DEFAULT_BG_COLOR, DEFAULT_DESKTOP_BG_COLOR, DEFAULT_HEADER_BG_COLOR, DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR, DEFAULT_TITLE_COLOR, DEFAULT_TOAST_BG_COLOR, DEFAULT_TOAST_COLOR, DEFAULT_WIDGET_BG_COLOR } from "../../utils/defaultStyling"
import { SET_COLOR_SCHEMA } from "../ActionTypes"

const initialState = {
        header: {
            headerBackgroundColor: DEFAULT_HEADER_BG_COLOR,
            headerTextColor: DEFAULT_TITLE_COLOR
        },
        background: {
            backgroundColor: DEFAULT_BG_COLOR,
            textColor: DEFAULT_APP_TEXT_COLOR,
            deskTopBackgroundColor: DEFAULT_DESKTOP_BG_COLOR
        },
        widget: {
            widgetBackgroundColor: DEFAULT_WIDGET_BG_COLOR
        },
        cta: {
            primaryColor: DEFAULT_PRIMARY_COLOR,
            secondaryColor: DEFAULT_SECONDARY_COLOR
        },
        toast: {
            toastBackgroundColor: DEFAULT_TOAST_BG_COLOR,
            toastTextColor: DEFAULT_TOAST_COLOR
        }
    }

const ColorSchemaReducer = (state = initialState, action) => {

  switch (action.type) {
    case SET_COLOR_SCHEMA:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default ColorSchemaReducer
