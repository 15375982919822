import { useSelector } from "react-redux"
import { Header } from "../../core/Header/Header"
import cardIllustration from "../../../assets/images/Card_Illustration_vertical.png"

const SetMpinIntro = () =>{

    const [preLogin] = useSelector(state => [state.PreLogin])
    return(
        <div>
            <Header headerTitle={"Setup MPIN"} />
            <div className="p-4 mt-3">
                <div className="flex-column-center">
                    <h5 style={{textAlign:'center'}}>{ preLogin?.mpinWelcomeScreen?.setMpinIntroTitle1 || "Congrats on becoming a Mool Cardholder"}</h5>
                    <div className="mt-2">{  preLogin?.mpinWelcomeScreen?.setMpinIntroTitle2 || "Your Card Interface is secured using an MPIN"}</div>
                    <div className="introImage p-4">
                        <img src={preLogin.mpinWelcomeScreen.setMpinIntroImage || cardIllustration} height='200px'/>
                    </div>
                </div>
                <div style={{fontWeight:"600"}}>
                    { preLogin?.mpinWelcomeScreen?.setMpinIntroParagraph1 || "To access and manage your card from this App, you will need to set-up the MPIN."}
                </div>
                <div className="mt-3" >
                    {preLogin?.mpinWelcomeScreen?.setMpinIntroParagraph2 || "In order to set up your MPIN, you will be required to complete the following steps:"}
                </div>
                <div className="mt-3" style={{fontWeight:"600"}}>
                    <ol>
                        {
                            preLogin?.mpinWelcomeScreen?.setMpinIntroSteps.length > 0 
                                ? preLogin?.mpinWelcomeScreen?.setMpinIntroSteps.split("\n").map((el,index) => <li key={index}>{el}</li>) : 
                                <> 
                                    <li>Verify your mobile number with OTP</li>
                                    <li>Verify your PAN and DOB</li>
                                </>
                        }
                    </ol>
                </div>
            </div>
        </div>
    )
}


export default SetMpinIntro