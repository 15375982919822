import React, { useEffect } from 'react'
import { Header } from '../core/Header/Header'
import './sessionExpired.css'
import ExpiredImage from "../../assets/images/sessionExpired.svg"
import { useDispatch, useSelector } from 'react-redux'
import { DEFAULT_WIDGET_BG_COLOR } from '../../utils/defaultStyling'
import { setColorSchema } from '../../store/actions/ColorSchema'

const SessionExpired = () => {
  const[ColorSchema] = useSelector(state=>[state.ColorSchema])
  const dispatch = useDispatch()
  useEffect(()=>{
    const handleMessage = async (event)=>{
        if(event.origin === 'http://localhost:8080'){
            const data = await JSON.parse(event.data)
            dispatch(
              setColorSchema(data.colorSchema)
            )
        }
    }
    window.addEventListener('message',handleMessage)
     
    return () =>{
        window.removeEventListener('message',handleMessage)
    }
},[])

  return (
    <div className='sessionExpired'>
        <Header headerTitle={"Session Expired"}/>
        <div className="sessionExpired-content">
          <div style={{backgroundColor:ColorSchema.widget.widgetBackgroundColor||DEFAULT_WIDGET_BG_COLOR}}  className="sessionExpired-imgContainer">
            <img src={ExpiredImage} alt="Session Expired" />
          </div>
          <div className="sessionExpired-headText">
              Session Expired
            </div>
          <div className="sessionExpired-secondaryText">
              Your session has expired, please go back and try again
          </div>
        </div>
    </div>
  )
}

export default SessionExpired
