export const Envs = Object.freeze({
  DEV: "dev",
  DEV_MOBILE: "dev-mobile",
  UAT: "uat",
  SANDBOX: "sandbox",
  PROD: "prod",
  AU_SANDBOX: "au-sandbox",
})

export const PwaVersions = Object.freeze({
  V1: "v1",
  V2: "v2",
})