import { SET_SETTINGS } from "../ActionTypes";

const initialState = {
    cardControlsAndLimits:{
        cardControlPeriodType:``,
    },
    myActiveEmis:{
        enableManageEmi:false,
        enableTransactionEmi:false,
        enableBalanceEmi:false,
        isEmiTncEnabled:false,
        emiTncUrl:``
    },
    blockOrReplaceCard:{
        isBlockReplaceCardEnabled:false,
        isBlockReplaceCardTncEnabled:false,
        blockReplaceCardTncCheckedByDefault:false,
        blockReplaceCardTncAction:``,
        blockReplaceCardTncUrl:``
    },
    changeMpin:{
        isResetMpinEnabled:false
    },
    customerCare:{
        isCustomerCareEnabled:false,
        customerCareDescText:``,
        customerCareMoblieNo:``,
        customerCareEmail:``,
    },
    aboutCardprogram:{
        isAboutProgramEnabled:false,
        aboutProgramDescription:``,
        cvp:[],
        faq:[],
        aboutProgramKnowMoreText:``,
        aboutProgramKnowMoreUrl:``
    }
}

const SettingsReducer = (state = initialState,action) =>{
    switch(action.type){
        case SET_SETTINGS:
            return{
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default SettingsReducer