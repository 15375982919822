import React from 'react'
import { useSelector } from 'react-redux'
import { DEFAULT_SECONDARY_COLOR } from '../../../utils/defaultStyling'

function Widget({url,text}) {
  const [colorSchema] = useSelector(state => [state.ColorSchema])
  return (
    <div className='mt-2 w-100'>
        <div className='home-widget-mainContainer'>
            <img src={url} />
            <div style={{paddingLeft:'15px',color:colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR,fontWeight:'600'}}>{text}</div>
        </div>
    </div>
  )
}

export default Widget