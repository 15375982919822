import React from 'react'
import './CardSummary.css'
import { useSelector } from 'react-redux'
import { DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR, DEFAULT_WIDGET_BG_COLOR } from '../../../../utils/defaultStyling';

const CardSummary = () => {
  const [theme, ColorSchema, home] = useSelector(state=>[state.theme, state.ColorSchema, state.Home]);
  return (
    <div style={{backgroundColor: ColorSchema.widget.widgetBackgroundColor?ColorSchema.widget.widgetBackgroundColor:DEFAULT_WIDGET_BG_COLOR}} className='home-cardSummaryWidget-mainContainer'>
      <div className="h-csw-mc-heading">
        Card Summary
      </div>
      <div className="h-csw-mc-infoContainer">
        <div className="h-csw-mc-ic-headingContainer">
          <div className="h-csw-mc-ic-info">
            <div className="h-csw-mc-ic-info1">
              Avalaible Credit
            </div>
            <div className="h-csw-mc-ic-info2">
              Avalaible Cash
            </div>
          </div>
        </div>
        <div className="h-csw-mc-ic-dataContainer">
        <div className="h-csw-mc-ic-data">
            <div className="h-csw-mc-ic-data1">
              ₹12,00,000
            </div>
            <div className="h-csw-mc-ic-data2">
              ₹10,00,000
            </div>
          </div>
        </div>
        
       { home.repaymentSection?.enableRepaymentFlow &&  <div >
          <div className='mt-3 d-flex align-items-end' >
            <div style={{fontWeight:'600'}}>₹27,392.00</div> 
            <span style={{fontSize:'12px',paddingLeft:'5px'}}>due on 23 Dec 2022</span>
          </div>
           <div className="h-csw-mc-ic-actions">
            <button style={{backgroundColor: ColorSchema.cta.primaryColor?ColorSchema.cta.primaryColor:DEFAULT_PRIMARY_COLOR, color: ColorSchema.cta.secondaryColor?ColorSchema.cta.secondaryColor:DEFAULT_SECONDARY_COLOR, border: "solid", borderWidth: "2px", borderColor:ColorSchema.cta.primaryColor?ColorSchema.cta.primaryColor:DEFAULT_PRIMARY_COLOR,fontSize:'14px'}} className="h-csw-mc-ic-actionBtn">
                  View Statement
              </button>
              <button style={{backgroundColor: ColorSchema.cta.primaryColor?ColorSchema.cta.primaryColor:DEFAULT_PRIMARY_COLOR, color: ColorSchema.cta.secondaryColor?ColorSchema.cta.secondaryColor:DEFAULT_SECONDARY_COLOR, border: "solid", borderWidth: "2px", borderColor:ColorSchema.cta.primaryColor?ColorSchema.cta.primaryColor:DEFAULT_PRIMARY_COLOR,fontSize:'14px'}} className="h-csw-mc-ic-actionBtn">
                  Pay Bill
              </button>
           </div>
        </div>}
      </div>
    </div>
  )
}

export default CardSummary
