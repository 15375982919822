import React from 'react'

function hexToRGBA(hex, opacity) {
    // Remove the '#' character from the beginning of the hex value
    hex = hex.replace('#', '');
  
    // Extract the RGB color values from the hex value
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
  
    // Return the RGBA value with the specified opacity
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
export default hexToRGBA