import React from 'react'
import { Header } from '../../core/Header/Header'
import { EmbeddedLink } from '../../core/EmbededLink'
import { useSelector } from 'react-redux'
import { DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR } from '../../../utils/defaultStyling'

const BlockCard = () => {
    const [colorSchema,settings] = useSelector(state => [state.ColorSchema,state.Settings])
  return (
    <>
        <Header headerTitle = "Block/Replace Card"/>
    <div className='p-3' style={{position:"relative",height:"90vh"}}>
        <div>
            <div className='home-widget-mainContainer mt-4 flex-column'>
                <div className='w-100 mb-4' style = {{
                    fontWeight: '700',
                    fontSize: '20px'
                }}>
                    Request a New Card
                </div>
                <div className='mb-2' style={{
                    fontWeight: '400',
                    fontSize: '16px',
                    color: 'gray'
                }}>
                    Your card will be delivered to your current billing address as below
                </div>
                <div className='p-3' style={{
                    backgroundColor: '#F5F5F5',
                    fontWeight: '600',
                    fontSize: '16px',
                    borderRadius: '8px'
                }}>
                    3rd floor, #227, Leela Kiran, Indiranagar, Bangalore - 530047
                </div>
            </div>
        </div>
        <div>
        <div className='p-4 w-100' style={{position:"absolute",left:"0px",bottom:"0px"}}>
        <div className='d-flex align-items-center flex-column' style={{paddingBottom:"8px"}}>
            <div className='d-flex'>
            {settings?.blockOrReplaceCard?.isBlockReplaceCardTncEnabled && <div className='d-flex align-items-center' style={{paddingBottom:"8px"}}>
                    <input type="checkbox" style={{height:'22px',width:'25px'}} checked={settings?.blockOrReplaceCard?.blockReplaceCardTncCheckedByDefault}/>
                    <div className='' style={{fontSize:'14px',marginLeft:'10px'}}><EmbeddedLink text={settings?.blockOrReplaceCard?.blockReplaceCardTncUrl  || "I accept the Terms & Conditions and give consent to activate my Credit Card"} /></div>
                </div>}
            </div>
            <button style={{backgroundColor: colorSchema.cta.primaryColor || DEFAULT_PRIMARY_COLOR, color: colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR, border: "solid", borderWidth: "2px", borderColor:colorSchema.cta.primaryColor || DEFAULT_PRIMARY_COLOR,fontSize:'14px',fontWeight:'bold'}} className='btn btn-primary w-100'>Submit</button>
        </div>
        </div>
        </div>
    </div>

    </>
  )
}

export default BlockCard
