import React from 'react'
import { Header } from '../../core/Header/Header'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { fontWeight } from 'styled-system';
import { ArrowForward, ArrowForwardIos } from '@mui/icons-material';
import { coinRewards, noJoiningFee, pointer } from '../../../assets/images';
import { DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR } from '../../../utils/defaultStyling';
import { useSelector } from 'react-redux';

const AboutCardWidgets=(
    {
    image,
    widgetDescriptionHeading,
    widgetDescriptionText,
    ifBorder = true
}
)=>{
    return (
        <>
        
                <div className='d-flex w-100 py-4 px-3'
                    style={ifBorder?{
                        borderBottom: "solid",
                        borderBottomWidth: "1px",
                        borderBottomColor: "#ECECEC",
                    }:{}}
                >
                    <img className='mt-2'
                    style={{
                        marginRight: '15px'
                    }}
                    src={image}/>
                    <div>
                        <div
                            style={{
                                fontWeight: '600',
                                fontSize: '16px'
                            }}
                        >{widgetDescriptionHeading}</div>
                        <div
                            style={{
                                fontWeight: '400',
                                fontSize: '14px',
                                color: 'gray'
                            }}
                        >{widgetDescriptionText}</div>
                    </div>
                </div>
        </>
    )
}

const AboutCardQuestions = (
    {questionText,
    answerText,
    color}
)=>{
    const answers = answerText.split("#") || []
    return(
        <>      
            <Accordion
                        style = {{
                            width: "100%",
                            boxShadow: 'none',
                            color: color
                        }}
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{height:'10px'}}
                        >
                             <div
                        style={{
                            maxWidth: "80%",
                            fontSize: '12px',
                            fontWeight: '600',
                            minHeight:'10px'
                            }}
                            >
                        {questionText}
                        </div>
                        </AccordionSummary>


                        <AccordionDetails>
                        <Typography
                                style={{
                                    height:'20px'
                                }}
                            >
                                {answers.map((item,index) => 
                                <div key={index} className='p-1 d-flex align-items-center'
                                style={{
                                fontSize: '12px',
                                fontWeight: '400'
                                }}
                                >
                                <img src={pointer} alt="pointer" />{item} 
                                </div>
                            )}
                                 
                            </Typography>
                            
                            
                        </AccordionDetails>
                    </Accordion>

        </>
    )
}




const AboutCardProgram = () => {
    const [colorSchema,settings] = useSelector(state => [state.ColorSchema,state.Settings])
  return (
    <>
        <Header headerTitle="About Card Program" />
        <div className='p-3' style = {{
            minHeight: '80vh',
            color: colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR
        }}>
            <div className="aboutCard-widget-container mt-3">
                {settings.aboutCardprogram.aboutProgramDescription?.length > 0 && <div className='home-widget-mainContainer mb-3'
                        style={{
                            flexDirection: "column",
                        }}
                >
                    <div className=''
                        style={{
                            fontSize: '14px',
                            textAlign: 'center',
                            fontWeight: '600'
                        }}
                    >
                        {settings.aboutCardprogram.aboutProgramDescription}
                    </div>
                    
                    </div>
                }
                <div style={{borderBottom: "solid",
                        borderBottomWidth: "2px",
                        borderBottomColor: "#ECECEC"}}></div>
                <div className='home-widget-mainContainer flex-column w-100 mt-3'

                >

                    <Accordion
                        style = {{
                            width: "100%",
                            boxShadow: 'none'
                        }}
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style = {{
                                margin:'0px'
                            }}
                        >
                            <div style={{
                                fontSize: '16px',
                                fontWeight: '600',
                                color: colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR
                            }}>
                                FAQ
                            </div>
                        </AccordionSummary>


                        <AccordionDetails>
                            {settings.aboutCardprogram.faq?.length > 0 && settings.aboutCardprogram.faq.map((item,index) => <div key={index}>
                            <Typography>
                                <AboutCardQuestions 
                                    questionText={item.question}
                                    answerText= {item.answer}
                                    color = {colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR}
                                />
                            </Typography>
                            </div>)}
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            <div className='mt-3' style = {{
                fontWeight: '400',
                fontSize: '13px'
            }}>
                {settings.aboutCardprogram?.aboutProgramKnowMoreText || 'Want to know more?'}
                {/* add cta colors */}
                <a 
                    style = {{
                        textDecoration: 'none',
                        fontWeight: '600',
                        marginLeft: '5px',
                        pointerEvents:'none',
                        color:'black'
                    }}
                href="">Click here <ArrowForwardIos style={{
                    height: '14px'
                }}/> </a>
            </div>
        </div>
        <div className='w-100 mt-3 p-3' style={{
            
        }}>
           <button style={{backgroundColor: colorSchema.cta.primaryColor || DEFAULT_PRIMARY_COLOR, color: colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR, border: "solid", borderWidth: "2px", borderColor:colorSchema.cta.primaryColor || DEFAULT_PRIMARY_COLOR,fontSize:'14px',fontWeight:'bold'}} className='btn btn-primary w-100'>Submit</button>
        </div>
    </>
  )
}

export default AboutCardProgram
