import moment from "moment"
import {
  ChevronRightIcon,
} from "native-base"
import { React, useState } from "react"
import { useWindowDimensions } from "react-native-web"
import { useSelector } from "react-redux"
import { Header } from "../../core/Header/Header"
import DateSelect from "../DateSelect/DateSelect"
import styles from "./AnalyseSpend.module.css"

import { TRANSACTION_LOGO_WIDTH } from "../../../utils/constants"
import { TRANSACTION_LOGO_HEIGHT } from "../../../utils/constants"
import OtherLogo from "../../../assets/images/Utility.svg"
import homeEmptyTransaction from "../../../assets/images/homeEmptyTransaction.svg"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const AnalyseSpend = () => {
  const [theme, screen, session, user, config] = useSelector(state => [
    state.theme,
    state.screen,
    state.session,
    state.user,
    state.config,
  ])
  const cycleStartDate = moment(user?.account?.currentCycleStartDate).date()
  const [currYear, setCurrYear] = useState(moment().year())
  const [currMonth, setCurrMonth] = useState(user?.spendGroup?.month || months[moment(user?.account?.currentCycleStartDate).month()])
  const [startDate, setStartDate] = useState(
    moment(
      currYear + "-" + (months.indexOf(currMonth) + 1) + "-" + cycleStartDate,
    ),
  )
  const [endDate, setEndDate] = useState(moment(user?.account?.currentCycleEndDate))
  const [spendData, setSpendData] = useState([])
  const [loading, setLoading] = useState(false)
  const currentMonth = moment().month()

  let summaryFound = false
  spendData?.forEach(spendGroup => {
    if (spendGroup.countOfTxns > 0) summaryFound = true
  })
  
  const windowDimensions = useWindowDimensions()
  let fromDate = moment(
    currYear + "-" + (months.indexOf(currMonth) + 1) + "-" + cycleStartDate,
  )
  let toDate = moment(
    currYear + "-" + (months.indexOf(currMonth) + 2) + "-" + cycleStartDate,
  ).subtract(1, "days")
  const handleSpendSummary = () => {
    setStartDate(moment(fromDate))
    setEndDate(moment(toDate))
    
  }

  const getTotalSpend = () => {
    let totalSpend = 0
    spendData?.map(spendGroup => {
      totalSpend += spendGroup.totalSpend
    })
    return totalSpend
  }

  return (
    <div style={{ minHeight: windowDimensions.height, backgroundColor: theme.backgroundColor }} >
      <Header headerTitle='Spend Analysis' />

      <div className={styles["analyseSpends-dateSelect"]} >
        <DateSelect
          onViewStatementsClick={handleSpendSummary}
          setCurrMonth={setCurrMonth}
          setCurrYear={setCurrYear}
          btnText={"View Spend Summary"}
          numOfYear={2}
          monthOffset={1}
          defaultMonth={user?.spendGroup?.month}
        />
      </div>
      {loading ? (
        <div className={styles["analyseSpends-loader"]}
          style={{
            backgroundColor: 'whitesmoke',
          }}
        >
          Custom Loader's Placeholder
        </div>
      ) : (
        <div className={styles["analyseSpends"]}>
          <div className={styles["analyseSpends-content"]}
          >
            <div className={styles["analyseSpends-content-text"]}
              style={{ color: theme.appTextColor }}

            >
              You have Spent
            </div>
            <div className={styles["analyseSpends-content-amount"]}
              style={{ color: theme.appTextColor }}  
            >
              &#8377; 0.00
            </div>
          </div>
          {summaryFound ? (
            <div className={styles["analyseSpends-widget"]}
              style={{ backgroundColor: theme.widgetBackgroundColor }}
            >
              <div className={styles["analyseSpends-widget-header"]} style={{ fontFamily: theme.fontFamily }}>
                {startDate.format("Do MMM")} -{" "}
                {endDate && endDate.month() != currentMonth
                  ? endDate.format("Do MMM")
                  : "Today"}
              </div>

              {spendData?.map((spendGroup, index) =>
                spendGroup.countOfTxns > 0 ? (
                  <div className={styles["analyseSpends-widget-transaction"]}
                    style={{
                      marginBottom: index === spendData.length - 1 ? "-12px" : "12px",
                      borderBottomWidth: index === spendData.length - 1 ? null : "1px"
                    }}
                    key={index}
                  >
                    <div className={styles["analyseSpends-widget-icon-container"]} >
                      {spendGroup.iconUrl ? (
                        <img
                          {...styles.image}
                          src={spendGroup.iconUrl}
                          alt='logo'
                          width={TRANSACTION_LOGO_WIDTH + "px"}
                          height={TRANSACTION_LOGO_HEIGHT + "px"}
                        />
                      ) : (
                        <img
                          src={OtherLogo}
                          alt='logo'
                          width={TRANSACTION_LOGO_WIDTH + "px"}
                          height={TRANSACTION_LOGO_HEIGHT + "px"}
                        />
                      )}
                    </div>

                    <div className={styles["analyseSpends-widget-transaction-content"]}
                    >
                      <div className={styles["analyseSpends-widget-transaction-content-div-1"]}>
                        <div className={styles["spendCategory-widget-transaction-content-description"]}
                          style={{ fontFamily: theme.fontFamily, color: theme.appTextColor }}
                        >
                          
                        </div>
                        <div className={styles["analyseSpends-widget-transaction-content-amount"]}
                        >
                          <div className={styles["analyseSpends-widget-transaction-content-amount-text"]}
                            style={{ fontFamily: theme.fontFamily, color: theme.appTextColor }}
                          >
                            Get spendGroup here
                          </div>
                          <ChevronRightIcon

                            color={theme.appTextColor}
                            size='25px'
                            width='fit-content'
                          />
                        </div>
                      </div>

                      <div className={styles["analyseSpends-widget-transaction-content-div-2"]}>
                        <div className={styles["analyseSpends-widget-transaction-content-percentage"]}
                          style={{ fontFamily: theme.fontFamily, color: theme.appTextColor }}
                        >
                          {Math.round(
                            (spendGroup.totalSpend / getTotalSpend()) * 100,
                          )}
                          %
                        </div>


                        <div className={styles["analyseSpends-widget-transaction-content-countOfTxn"]}
                        >

                          <div className={styles["analyseSpends-widget-transaction-content-countOfTxn-label"]}
                            {...styles.transactionContent}
                            textAlign={"end"}
                          >
                            {spendGroup.countOfTxns} Transactions
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                ),
              )}
            </div>
          ) : (
            <div className={styles["analyseSpends-noSpends"]}
              style={{ backgroundColor: theme.widgetBackgroundColor }}
            >
              <div className={styles["analyseSpends-noSpends-content"]}>
                <img
                  className={styles["analyseSpends-noSpends-content-img"]}
                  src={screen?.home?.transaction?.transactioEmptyImageUrl ||
                    homeEmptyTransaction}
                  source={{
                    uri:
                      screen?.home?.transaction?.transactioEmptyImageUrl ||
                      homeEmptyTransaction,
                  }}
                  alt='Alternate Text '
                />
                <div className={styles["analyseSpends-noSpends-content-text-1"]}
                  style={{ fontFamily: theme.fontFamily, color: theme.appTextColor }}
                >
                  No Spends Yet
                </div>
                <div className={styles["analyseSpends-noSpends-content-text-2"]}
                  style={{ fontFamily: theme.fontFamily, color: theme.appTextColor }}

                >
                  Track your monthly spends based on category
                </div>

              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default AnalyseSpend
