import React from 'react'
import "./Offers.css"
import { ArrowForwardIos } from '@mui/icons-material'
import reward from '../../../../assets/images/rewardsTransactionLogo.svg'
import { useSelector } from 'react-redux'
import { DEFAULT_APP_TEXT_COLOR, DEFAULT_DESKTOP_BG_COLOR } from '../../../../utils/defaultStyling'

const Offers = () => {
  const [colorSchema] = useSelector(state => [state.ColorSchema])
  const style = {backgroundColor:colorSchema?.widget?.widgetBackgroundColor || DEFAULT_DESKTOP_BG_COLOR,color:colorSchema?.background?.textColor || DEFAULT_APP_TEXT_COLOR}
  return (
    <div className='home-widget-mainContainer' style={style}>
      <div>
        <div className='d-flex align-items-center justify-content-between'>
          <div style={{fontWeight:'600',fontSize:'18px',color:'black !important'}}>
              Offers & Promotions
          </div>
          <div>
            <a className='d-flex align-items-center' href='#' style={{pointerEvents:'none'}}>
              <div>View All</div>
              <ArrowForwardIos style={{fontSize:'14px'}}/>
            </a>
          </div>
        </div>
        <div className='d-flex p-3 mt-2' style={{backgroundColor:'#d9eaf9',borderRadius:'10px'}}>
          <div className='img'>
            <img src={reward} />
          </div>
          <div className='content' style={{marginLeft:'15px'}}>
            <div>
              Flat ₹250 off on all orders above ₹10,000 on amazon.in
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Offers