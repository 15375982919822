import React from 'react'
import { Header } from '../../core/Header/Header'
import { Toggle } from '../../../core/Toggle'
import { backgroundColor, borderRadius, borderRight } from 'styled-system'
import { Slider } from '../../../core/Slider'
import { DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR } from '../../../utils/defaultStyling'
import { useSelector } from 'react-redux'
import { cashWithDraw, contactLess, offline, online } from '../../../assets/images'
import CardControlWidget from '../components/CardControlWidget'
import hexToRGBA from '../../../core/HextoRgba'

const CardControls = () => {

  const [colorSchema] = useSelector(state => [state.ColorSchema])
 

  return (
    <>
        <Header headerTitle="Card Controls" />
        <div className='p-3' style={{color:colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR}}>
        <div className="home-widget-mainContainer mt-3 d-flex w-100 justify-content-between"
        style={{
          fontSize:'14px',
          fontWeight: 'bold',
          color:colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR
        }}
        >
            <div className="CardControls-widget1-text">
                Card Status
            </div>
            <div>
                <Toggle checked={true}/>
            </div>
        </div>
        <div className='home-widget-mainContainer mt-3' style={{textAlign:'center',padding:'0px',display:'block'}}>
              <div className='d-flex w-100' >
                <div className='w-50' style={{
                borderBottom:`2px solid ${colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR}`,
                padding: "16px",
                backgroundColor: hexToRGBA('#1F0600', 0.3),
                borderTopLeftRadius: "15px",
                fontSize: "14px",
                fontWeight: 'bold'
              }}>
                Domestic
                </div>
                <div className='w-50' style={{
                padding: "16px",
                fontSize: "14px",
                fontWeight: 'bold'
                
              }}>
                International
                </div>
              </div>
              <div className='p-2'>
               <CardControlWidget title="Online Transactions" url={online} checked={true} />

               <CardControlWidget title="Offline Transactions" url={offline} checked={false} />
               <CardControlWidget title="Contactless" url={contactLess} checked={false} />
               <CardControlWidget title="Cash Withdrawl" url={cashWithDraw} checked={false} />
              
              </div>
        </div>
        </div>
    </>
  )
}

export default CardControls
