import moment from "moment"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import styles from './DateSelect.module.css'

import downFilledArrow from "../../../assets/images/downFilledArrow.svg"
import { ProgramTypes } from "../../../utils/constants"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
let currMonth = months[moment().month()]
const currYear = moment().year()

let years = []

const DateSelect = ({
  setCurrYear,
  setCurrMonth,
  onViewStatementsClick,
  btnText,
  numOfYear,
  monthOffset,
  defaultMonth,
}) => {
  const [theme, user] = useSelector(state => [state.theme, state.user])
  monthOffset = monthOffset || 0
  let timeString = new Date();
  let currMonthIndex = timeString.getMonth()+1;

  const [selectedYear, setSelectedYear] = useState(currYear)
  const [selectedMonth, setSelectedMonth] = useState(currMonth)
  const [yearScrollOpen, setYearScrollOpen] = useState(false)
  const [monthScrollOpen, setMonthScrollOpen] = useState(false)

  useEffect(() => {
    if (defaultMonth) {
      setSelectedMonth(defaultMonth)
    }
    let setYear = currYear
    for (let ind = 0; ind < numOfYear; ind++) {
      years[ind] = setYear-- + ""
    }
  }, [])

  return (
    <div
      className={styles['dateSelect']}
      style={{ backgroundColor: theme.widgetBackgroundColor }}
    >
      <div className={styles['dateSelect-container']}>
        <div className={styles['dateSelect-container-box']}>
          <div className={styles['dateSelect-container-box-dropdown']}>
            <div
              className={styles['dateSelect-container-box-dropdown-content']}
              onClick={() => {
                setYearScrollOpen(!yearScrollOpen)
              }}
            >
              <div
                className={styles['dateSelect-container-box-dropdown-content-text']}
                style={{ color: theme.appTextColor }}
              >
                {selectedYear}
              </div>
              <div
                className={styles['dateSelect-container-box-dropdown-content-imgBox']}
              >
                <img
                  className={styles['dateSelect-container-box-dropdown-content-img']}
                  src={downFilledArrow}
                  alt='Alternate Text '
                />
              </div>
            </div>
            <div
              className={
                yearScrollOpen
                  ? styles["dateSelect-container-box-dropdown-contentOpen-year"]
                  : styles["dateSelect-container-box-dropdown-contentClose-year"]
              }
              style={{
                height: 30 + numOfYear * 28 + "px",
                backgroundColor: theme.widgetBackgroundColor,
              }}
            >
              <div className={styles['dateSelect-container-box-dropdown-contentOpen-year-scroll']}>
                {years.map((year, key) => {
                  return (
                    <>
                      <div
                        className={
                          year === selectedYear
                            ? styles["dateSelect-container-box-dropdown-contentOpen-yearSelected-text"]
                            : styles["dateSelect-container-box-dropdown-contentOpen-yearNotSelected-text"]
                        }
                        style={{
                          backgroundColor:
                            year == selectedYear
                              ? "black"
                              : "white",
                          color:
                            selectedYear == year
                              ? "white"
                              : "black",
                        }}
                        key={key}
                        onClick={() => {
                          setSelectedYear(year)
                          setCurrYear(year)
                          setYearScrollOpen(false)
                        }}
                      >
                        {year}
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>

          <div className={styles['dateSelect-container-box-dropdown']}>
            <div
              className={styles['dateSelect-container-box-dropdown-content']}
              onClick={() => {
                setMonthScrollOpen(!monthScrollOpen)
              }}
            >
              <div
                className={styles['dateSelect-container-box-dropdown-content-text']}
                style={{ color: theme.appTextColor }}
              >
                {selectedMonth}
              </div>
              <div className={styles['dateSelect-container-box-dropdown-content-imgBox']}>
                <img
                  className={styles['dateSelect-container-box-dropdown-content-img']}
                  src={downFilledArrow}
                  alt='Alternate Text '
                />
              </div>
            </div>
            <div
              className={
                monthScrollOpen
                  ? styles["dateSelect-container-box-dropdown-contentOpen-month"]
                  : styles["dateSelect-container-box-dropdown-contentClose-month"]
              }
              style={{
                height:
                  selectedYear == currYear
                    ? 35 + (currMonthIndex + 1) * 29 + "px"
                    : "340px",
                backgroundColor: theme.widgetBackgroundColor,
              }}
            >
              <div className={styles['dateSelect-container-box-dropdown-contentOpen-month-scroll']}>
                {months.map((month, key) => {
                  if (selectedYear == currYear) {
                    if (key <= months.indexOf(currMonth)) {
                      return (
                        <>
                          <div
                            className={
                              month === selectedMonth
                                ? styles["dateSelect-container-box-dropdown-contentOpen-monthSelected-text"]
                                : styles["dateSelect-container-box-dropdown-contentOpen-monthNotSelected-text"]
                            }
                            style={{
                              backgroundColor:
                                month === selectedMonth
                                  ? "black"
                                  : "white",
                              color:
                                selectedMonth == month
                                  ? "white"
                                  : "black",
                            }}
                            key={key}
                            onClick={() => {
                              setSelectedMonth(month)
                              setCurrMonth(month)
                              setMonthScrollOpen(false)
                            }}
                          >
                            {month}
                          </div>
                        </>
                      )
                    }
                  } else {
                    return (
                      <>
                        <div
                          className={
                            month === selectedMonth
                              ? styles["dateSelect-container-box-dropdown-contentOpen-monthSelected-text"]
                              : styles["dateSelect-container-box-dropdown-contentOpen-monthNotSelected-text"]
                          }
                          style={{
                            backgroundColor:
                            month === selectedMonth
                              ? "black"
                              : "white",
                          color:
                            selectedMonth == month
                              ? "white"
                              : "black",
                          }}
                          key={key}
                          onClick={() => {
                            setSelectedMonth(month)
                            setCurrMonth(month)
                            setMonthScrollOpen(false)
                          }}
                        >
                          {month}
                        </div>
                      </>
                    )
                  }
                })}
              </div>
            </div>
          </div>
        </div>

        <div
          className={styles['dateSelect-button']}
          style={{ backgroundColor: "black" }}
        >
          <div
            className={styles['dateSelect-button-text']}
            style={{ color: "white", fontFamily: theme.fontFamily }}
          >
            {btnText}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DateSelect
